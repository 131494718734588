/* reset.css or normalize.css */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin and padding */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Remove list styles (bullet points and numbers) */
ul,
ol {
  list-style: none;
}

/* Set body and default font styles */
body {
  line-height: 1.6;
  font-size: 16px;
}

/* Remove focus outlines for clickable elements */
a,
button,
input,
textarea,
select {
  outline: none;
}

/* Ensure text remains selectable and cursor is text in input elements */
input,
textarea {
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
}

/* Style links */
a {
  text-decoration: none;
  color: inherit;
}

/* Add a background color for hover/focus states on links */
a:hover {
  background-color: none;
}
a:focus 
  

/* Add a background color for active states on links */
a:active {
  background-color: #ccc;
}

/* Remove underlines from links in buttons and certain other elements */
button,
[type="button"],
[type="reset"],
[type="submit"],
[type="file"] {
  -webkit-appearance: button;
  cursor: pointer;
  text-decoration: none;
}

/* Remove default button styles */
button {
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
}

/* Make images responsive */
img {
  max-width: 100%;
  height: auto;
}
