@import "./constant.scss";

// Path: src/styles/constant.scss
.coOrdinateContainer {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: $primaryColor;
}

.Map_container {
  flex: 1;
  //   border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.No_map_container {
  // border:1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  text-align: center;
  gap: 1.6rem;
  p {
    color: rgba(54, 54, 54, 0.5);
    font-weight: 500;
  }
}

.sidebar_Container {
  width: 420px;
  // border:1px solid blue;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 2rem;
  gap: 10px;
  position: relative;
}

.upload_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
  gap: 16px;
  margin: 16px 0px 0px 0px;
  background: #eaeaea;
  border-radius: 8px;
}
.upload_input_container input::file-selector-button {
  padding: 12px 16px;
  margin: 0px 20px 0px 2px;
  color: white;
  background: #7f2f92;
  border-radius: 8px;
  cursor: pointer;
  border: 0;
  // display: none;
}

.upload_input_container input::file-selector-button:hover {
  background-color: #8d28a7;
  // box-shadow: 0 0 0 2px #77208d;
}
.Room_heading {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}
.sub_head {
  font-size: 0.8rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.mid_section {
  //   border: 1px solid red;
  max-height: 65%;
  overflow-y: scroll;
}

.mid_section::-webkit-scrollbar {
  display: none;
}

.room_input {
  display: flex;
  height: 55px;
  margin-top: 1rem;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  border: 2px solid #c7c7c7;
  background: #fff;
}

.room_inputBox {
  width: 65%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  background: transparent;
}

.room_add_btn {
  display: flex;
  height: 37px;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #7f2f92;
  color: #fff;
}

.room_card_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // margin-top: 1rem;
  border-radius: 10px;
  border: 2px solid #dedede;
  background: #efefef;
  padding: 16px 10px;
}
.room_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
}

.assets_card {
  display: flex;
  gap: 0.5rem;
}

.assets_card_box {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  background: #fff;
  color: #7f2f92;
  position: relative;
}
.export_btn {
  position: absolute;
  bottom: 20px;
  width: 85%;
  border-radius: 10px;
  background: #7f2f92;
  display: flex;
  height: 55px;
  padding: 26px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}

.room_editdown_btn {
  border-radius: 100%;
  background: #7f2f92;
  display: flex;
  width: 25px;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.room_editup_btn {
  display: flex;
  width: 25px;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100%;
  background: rgba(195, 195, 195, 0.5);
}

.remove_btn_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.room_remove_btn {
  display: flex;
  padding: 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #7f2f92;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.remove_device {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7f2f92;
  top: -20%;
  right:-20%;
  font-size: 14px;
  font-weight: 600;
  display: none;
}

.assets_card_box:hover .remove_device {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7f2f92;
  top: -20%;
  right:-20%;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}